.names {
  flex-direction: column;
  padding: .6em 1.5em;
  font-size: 2.4em;
  line-height: 1.5em;
  display: flex;
}

.names ol {
  margin: 0;
}

/*# sourceMappingURL=index.9797611e.css.map */
